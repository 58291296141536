
import axios from 'axios';


const saveProjectRedirectionUrls = async (formdata) => {
    const response = await axios.post('/api/projects/save-project-redirection-urls', formdata);
    return response.data;
}

const getProjectRedirectionUrls = async (formdata) => {
    const response = await axios.post('/api/projects/get-project-redirection-urls', formdata);
    return response.data;
}

export default {
    saveProjectRedirectionUrls,
    getProjectRedirectionUrls,

}