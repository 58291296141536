import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
    Row,
    Col,
    Spinner,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faPlusCircle,
    faTrash,
    faExclamationTriangle,
    faUndo
} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import projectRedirectionUrlsService from "../../services/api/redirection-urls-service";
import projectsService from "../../services/api/projects-service";
import ProjectContext from '../../ProjectContext'

// const urlRegex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-]*)*$/;

const colors = {
    background: '#F4F6F9',
    cardBackground: '#FFFFFF',
    secondary: '#6C757D',
    success: '#48BB78',
    error: '#E53E3E',
    accent: '#667EEA',
    text: {
        dark: '#2D3748',
        light: '#718096'
    }
};

const RedirectionUrls = (props) => {
    const projectDetails = useContext(ProjectContext);
    const history = useHistory();

    if (projectDetails.id === '') {
        history.push("/project/allprojects");
    }
    const projectId = projectDetails.id;

    let getProjectSlug = new URLSearchParams(props.location.search).get('project');
    if (!getProjectSlug) {
        history.push({
            search: '?project=' + projectDetails.slug
        })
    }

    const [urls, setUrls] = useState([]);
    const [domain, setDomain] = useState('');
    const [url, setUrl] = useState({
        source_url: "",
        destination_url: "",
        status_code: "",
    });
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [toDeleteIndex, setToDeleteIndex] = useState(null);
    const [loading, setLoading] = useState(true);

    const [statusCodeOptions, setStatusCodeOptions] = useState([
        { label: "301", value: "301" },
        { label: "302", value: "302" },
        { label: "303", value: "303" },
        { label: "307", value: "307" },
        { label: "410", value: "410" },
    ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [urlsResponse, domainResponse] = await Promise.all([
                    projectRedirectionUrlsService.getProjectRedirectionUrls({ project_id: projectId }),
                    projectsService.getProjectDomain(projectId)
                ]);

                if (urlsResponse && urlsResponse.status) {
                    setUrls(urlsResponse.data?.urls || []);
                    const existingStatusCodes = urlsResponse.data?.urls.map(url => url.status_code);
                    const newStatusCodes = existingStatusCodes.filter(code =>
                        !statusCodeOptions.some(option => option.value === code)
                    );
                    if (newStatusCodes.length > 0) {
                        const newOptions = newStatusCodes.map(code => ({
                            label: code,
                            value: code
                        }));
                        setStatusCodeOptions(prevOptions => [...prevOptions, ...newOptions]);
                    }
                }


                if (domainResponse && domainResponse.status) {
                    const projectDomain = domainResponse.data;
                    if (!projectDomain.public_host.startsWith('https://')) {
                        setDomain("https://" + projectDomain.public_host);
                    } else {
                        setDomain(projectDomain.public_host);
                    }
                }
            } catch (error) {
                toast.error("Failed to load data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [projectId]);

    const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            centered
            className="custom-modal"
        >
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody className="text-center p-4">
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="3x"
                    color={colors.error}
                    className="mb-3"
                />
                <p className="text-muted">{message}</p>
            </ModalBody>
            <ModalFooter className="justify-content-center border-top-0">
                <Button
                    color="danger"
                    onClick={onConfirm}
                    className="px-4 hover:opacity-90"
                >
                    Confirm
                </Button>{" "}
                <Button
                    color="secondary"
                    onClick={onClose}
                    className="px-4 hover:opacity-90"
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUrl({ ...url, [name]: value.trim() });
    };

    const handleStatusCodeChange = (selectedOption) => {
        const newStatusCode = selectedOption ? selectedOption.value : "";
        const newStatusCodeDesc = selectedOption ? selectedOption.label.split(" - ")[1] : "";

        setUrl({
            ...url,
            status_code: newStatusCode,
            status_code_desc: newStatusCodeDesc,
        });

        if (selectedOption && selectedOption.__isNew__) {
            const newOption = {
                label: selectedOption.label,
                value: selectedOption.value,
            };
            setStatusCodeOptions((prevOptions) => [...prevOptions, newOption]);
        }
    };

    const resetForm = () => {
        setUrl({
            source_url: "",
            destination_url: "",
            status_code: "",
        });
        setIsEdit(false);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        setUrl(urls[index]);
        setIsEdit(true);
        setEditIndex(index);
    };

    const handleDeleteConfirmation = (index) => {
        setDeleteModal(true);
        setToDeleteIndex(index);
    };

    const saveData = async (docData) => {
        try {
            const response = await projectRedirectionUrlsService.saveProjectRedirectionUrls({
                project_id: projectId,
                ...docData,
            });

            console.log(response);
            if (response && response.status) {
                setUrls(response.data?.urls || []);
                toast.success(`${docData.action ? `${docData.action.charAt(0).toUpperCase() + docData.action.slice(1)}` : 'Action'} successful!`);

            } else {
                toast.error(response?.error?.message || "Failed to save data");
            }
            resetForm();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = () => {
        saveData({
            action: "delete",
            toDeleteIndex: toDeleteIndex,
            url: urls[toDeleteIndex]
        });
        setDeleteModal(false);
        setToDeleteIndex(null);
    };
    const validateStatusCode = (code) => {
        return /^[0-9]{3}$/.test(code);
    };

    const isValidUrl = async (url) => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            console.error("Invalid URL:", e);
            return false;
        }
    };

    const handleSubmit = async () => {
        if (!url.source_url || !url.destination_url || !url.status_code) {
            toast.error("Please fill all the fields");
            return;
        }

        const fullSourceUrl = `${domain}${url.source_url}`;
        if (fullSourceUrl === url.destination_url) {
            toast.error("Source URL and Destination URL must not be the same!");
            return;
        }

        if (!url.source_url.startsWith("/")) {
            toast.error("Source URL must start with a '/'");
            return;
        }

        const isDuplicate = urls.some(
            (data, index) => data.source_url === url.source_url && index !== editIndex
        );

        if (isDuplicate) {
            toast.error("Source URL must be unique!");
            return;
        }


        if (!validateStatusCode(url.status_code)) {
            toast.error("Status code must be a valid 3-digit number!");
            return;
        }


        const isDestinationValid = await isValidUrl(url.destination_url);
        if (!isDestinationValid) {
            toast.error("Destination URL is not valid!");
            return;
        }

        const action = isEdit && editIndex !== null
            ? { action: "edit", editIndex: editIndex, url: url }
            : { action: "save", url: url };

        saveData(action);
    }

    return (
        <div className="content mt-4" style={{ backgroundColor: colors.background }}>
            <Container>
            <Card className="shadow-sm" style={{ backgroundColor: colors.cardBackground, borderRadius: '10px', }}>
                        <CardHeader
                            className="text-left font-semibold py-3"
                            tag='h2'
                            style={{
                                bottom: '-10px',
                                color: 'black',
                                borderBottom: '3px solid',
                                position: 'relative',
                            }}
                        >
                            Redirection URLs
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '15px',
                                    right: '10px',
                                    fontSize: '0.8rem',
                                    color: '#555',
                                }}
                            >
                                Domain: {domain}
                            </div>
                        </CardHeader>
                    <CardBody>
                        {loading ? (
                            <div className="text-center py-5">
                                <Spinner color="info" />
                                <p>Loading Redirection URLs...</p>
                            </div>
                        ) : (
                            <>
                                <Form>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="source_url">Source URL</Label>
                                                <Input
                                                    type="text"
                                                    name="source_url"
                                                    id="source_url"
                                                    value={url.source_url}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Source URL"
                                                    className="form-control-lg"
                                                    autoComplete="off"
                                                />
                                                {url.source_url && (
                                                    <small
                                                        className="text-muted position-absolute"
                                                        style={{
                                                            fontSize: '0.7rem',
                                                            bottom: '-1.5rem',
                                                            left: 0,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        {domain}{url.source_url}
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="destination_url">Destination URL</Label>
                                                <Input
                                                    type="text"
                                                    name="destination_url"
                                                    id="destination_url"
                                                    value={url.destination_url}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Destination URL"
                                                    className="form-control-lg"
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="status_code">Select Status Code or Add New</Label>
                                                <CreatableSelect
                                                    isMulti={false}
                                                    options={statusCodeOptions}
                                                    name="status_code"
                                                    id="status_code"
                                                    value={
                                                        url.status_code
                                                            ? statusCodeOptions.find(option => option.value === url.status_code)
                                                            : null
                                                    }
                                                    onChange={handleStatusCodeChange}
                                                    formatCreateLabel={(inputValue) => `Add new code: "${inputValue}"`}
                                                    placeholder="Select Status Code"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            <div className="btn-group">
                                                <Button
                                                    color="info"
                                                    onClick={handleSubmit}
                                                    className="mr-2"
                                                    style={{ cursor: "pointer", marginRight: "10px" }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={isEdit ? faEdit : faPlusCircle}
                                                        style={{ fontSize: '1rem' }} />
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    onClick={resetForm}
                                                >
                                                    <FontAwesomeIcon icon={faUndo}
                                                        style={{ fontSize: '1rem' }} />
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>

                                <Table
                                    striped
                                    hover
                                    responsive
                                    className="mt-4"
                                >
                                    <thead style={{ backgroundColor: colors.background }}>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Source URL</th>
                                            <th>Destination URL</th>
                                            <th>Status Code</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {urls.length > 0 ? (
                                            urls.map((url, index) =>
                                                index !== editIndex && (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{url.source_url}</td>
                                                        <td>{url.destination_url}</td>
                                                        <td>{url.status_code}</td>
                                                        <td>
                                                            <div className="btn-group btn-group-sm">
                                                                <Button
                                                                    color="info"
                                                                    onClick={() => handleEdit(index)}
                                                                    style={{ cursor: "pointer", marginRight: "20px" }}                                                                >
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </Button>
                                                                <Button
                                                                    color="danger"
                                                                    onClick={() => handleDeleteConfirmation(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan='5' className='text-center text-muted'>
                                                    No Redirection URLs found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </CardBody>
                </Card>
                <ConfirmationModal
                    isOpen={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    onConfirm={handleDelete}
                    title="Confirm Deletion"
                    message="Are you sure you want to delete this URL?"
                />
                <ToastContainer
                    autoClose={2000}
                    position='bottom-right'
                    newestOnTop
                    theme="colored"
                />
            </Container>
        </div>
    );
}

export default RedirectionUrls;